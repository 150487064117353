import React from 'react'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import './index.scss'
import { Empty, Table } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import NoData from '../../../components/NoData';

const CPortofolio = () => {

    const lineChart1 = {
        chart: {
            // type: "line",
            type: 'spline',

            backgroundColor: null,
            height: "250",
            // width: "100",
            style: {
                fontFamily: 'Montserrat'
            },
            className: "dashboard_charts",
        },
        className: "dashboard_charts",
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: true,
        },
        legend: {
            enabled: false, // Disable the legend
        },
        plotOptions: {

            line: {
                showInLegend: false,
                lineWidth: 1,
                lineColor: "#516FB6",
                fillColor: false,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 8,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            spline: {
                lineWidth: 2,
                marker: {
                    enabled: false
                },
            }
        },
        yAxis: {
            // gridLineColor: "transparent",
            gridLineDashStyle: "dot",
            lineWidth: 0,
            lineColor: '#84CEE9',
            title: {
                text: null,
            },
            labels: {
                style: {
                    color: '#111111',
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        },
        xAxis: {
            categories: ['05/11', '05/11', '05/11', '05/11', '05/11', '05/11', '05/11'],
            className: 'chart-x-lebel',
            lineWidth: 0,
            lineColor: '#84CEE9',
            labels: {
                style: {
                    color: '#111111',
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        },
        series: [
            {
                data: [1800, 5100, 2800, 6500, 4000, 5500],
                lineColor: {
                    linearGradient: [0, '100%', 0, '20%'],
                    stops: [
                        // [0, '#0FBFFF00'],
                        [1, '#2D60FF']
                    ]
                },
                // enableMouseTracking: false,
            },
        ],
    };

    const dataSource = [
        // {
        //     key: '1',
        //     token: 'LPTOKEN',
        //     tokenPrice: "1,932 USDC",
        //     position: "1,932 USDC",
        //     marketValue: "1,932 USDC",
        //     poolStatus: "1,932 USDC",
        // },
    ];

    const columns = [
        {
            title: 'Token',
            dataIndex: 'token',
            key: 'token',
        },
        {
            title: 'Token Price',
            dataIndex: 'tokenPrice',
            key: 'tokenPrice',
        },
        {
            title: 'Positions',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Market Value',
            dataIndex: 'marketValue',
            key: 'marketValue',
        },
        {
            title: 'Pool Status',
            dataIndex: 'poolStatus',
            key: 'poolStatus',
        },
    ];

    // const CustomNoDataIcon = () => (
    //     <Empty
    //         image={NoData}
    //         imageStyle={{
    //             height: 110,
    //         }}
    //         description={<span></span>}
    //     />
    // );



    return (
        <>
            <div className="cportifolio_main_container">
                <div className="cportifolio_container">
                    <div className="cportofolio_overview_container ">
                        <div className="card_title" style={{ marginTop: "0", marginBottom: "1rem" }}>
                            Overview
                        </div>
                        <div className="card_container">
                            <div className="title">Current Portfolio Value</div>
                            <div className="value">1,101,337 USDC</div>
                            <div className="cportofolio_overview_graph_container">
                                <HighchartsReact highcharts={Highcharts} options={lineChart1} />
                            </div>
                        </div>
                    </div>
                    <div className="cportofolio_holding_container">
                        <div className="card_title" >
                            Holdings
                        </div>
                        <div className="tbale_container">
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                className='custom_table'
                                locale={{ emptyText: <NoData /> }}

                            />
                        </div>
                    </div>
                    <div className="cportofolio_transaction_history_container">
                        <div className="card_title" >
                            Transaction History
                        </div>
                        <div className="tbale_container">
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                className='custom_table'
                                locale={{ emptyText: <NoData /> }}
                            />
                        </div>
                    </div>
                    <div className="cportofolio_allocation_container">
                        <div className="card_title" >
                            Allocations
                        </div>
                        <div className="tbale_container">
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                className='custom_table'
                                locale={{ emptyText: <NoData /> }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default CPortofolio